@import './../sass/reuse';

.trendingSwiper {
  cursor: all-scroll;
  position: relative;
  overflow: hidden;
  min-height: 280px;

  @media (max-width: 630px) {
    min-height: 230px;
  }
  @media (max-width: 530px) {
    min-height: 200px;
  }
  @include tablet {
    min-height: 350px;
  }
  @include widescreen {
    min-height: 290px;
  }
  @media (min-width: 1532px) {
    min-height: 340px;
  }
}

.trending {
  a + div {
    padding: 8px 38px 38px 38px;
  }
}
